@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos Headline"), url("./fonts/TiemposHeadlineWeb-Bold.woff") format("woff");
}

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f9ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "Tiempos Headline", sans-serif;
  font-weight: 700;
  letter-spacing: -0.02em;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
[class*="circ"] {
  margin-top: -22px;
  position: absolute;
  width: 24px;
  height: 24px;
  background: none;
  border-radius: 50%;
  overflow: hidden;
  border-style: solid;
  border-width: 3px;
  box-sizing: border-box;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-duration: 2s;
}
.circ_1 {
  clip-path: inset(0 12px 12px 0);
  border-color: #38c4f2;
}
.circ_2 {
  clip-path: inset(0 0 12px 12px);
  border-color: #fb0;
}
.circ_3 {
  clip-path: inset(12px 0 0 12px);
  border-color: #e11f59;
}
.circ_4 {
  clip-path: inset(12px 12px 0 0);
  border-color: #4b0096;
}
.circ_5 {
  clip-path: inset(0 12px 12px 0);
  border-color: #2fb67d;
}
.circ_6 {
  clip-path: inset(0 0 12px 12px);
  border-color: #1f6fff;
}
.circ_1 {
  animation-delay: 0.125s;
}
.circ_2 {
  animation-delay: 0.25s;
}
.circ_3 {
  animation-delay: 0.375s;
}
.circ_4 {
  animation-delay: 0.5s;
}
.circ_5 {
  animation-delay: 0.625s;
}
.circ_6 {
  animation-delay: 0.75s;
}
